import {graphql, useStaticQuery} from "gatsby";
import React from 'react'

import ThankYou from "../components/thank-you/ThankYou.js";
import Layout from '../components/layout/en/Layout'
import Seo from '../components/seo'

const ThankYouPage = (props) => {
  
  const {strapiThankYouPage} = useStaticQuery(query);
  
  return (
    <Layout path={props.location.pathname} black={true}>
      <Seo
        title={strapiThankYouPage.metaTitle}
        description={strapiThankYouPage.metaDescription}
        keywords={strapiThankYouPage.metaKeywords} />
      <ThankYou strapiThankYouPage={strapiThankYouPage}/>
    </Layout>
  )
}

export default ThankYouPage

const query = graphql`
    query {
        strapiThankYouPage(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            subtitle
            button
        }
    }
`;
