import React from 'react'
import styled from 'styled-components'
import {navigate} from "gatsby";
import headerLogo from '../../../static/images/logo/logo.svg'
import Button from "../library/Button.js";
import H2 from '../library/H2.js'
import Body2 from '../library/Body2.js'

const ThankYou = ({strapiThankYouPage}) => {
  
  return (
    <ThankYouStyle>
      <Left>
        <H2>{strapiThankYouPage.title}</H2>
        <Body2>{strapiThankYouPage.subtitle}</Body2>
        <Button label={strapiThankYouPage.button} onClick={() => {navigate('/')}}/>
      </Left>
      <Right />
    </ThankYouStyle>
  )
}

const ThankYouStyle = styled.div`
    min-height: calc(100vh - 5rem);
    display: grid;
    justify-items: center;
    justify-content: center;
    align-content: center;
    margin-top: -30px;
    `
const Left = styled.div`
  text-align: center;
  * {
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
    width: 50%;
    background-image: url(${headerLogo});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
`

export default ThankYou
